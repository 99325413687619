<template>
    <div class="user--view">
        <div class="navbar">
            <button class="back__button" @click="goToMainList"></button>
            <div class="user-info--container">
                <div class="account-info--container">
                    <div class="avatar--container">
                        <img :src="user.avatar">
                    </div>
                    <div class="name--container">
                        <p class="name__text">{{user.name}}</p>
                        <p class="category__text">{{getUsersCategory(user)}}</p>
                    </div>
                </div>
                <div class="indicator--container">
                  <p><a class="icon-camera"></a>{{getSumCameras(user)}}</p>
                    <p><a class="icon-finance"></a>0</p>
                    <p v-if="user.type === 2"><a class="icon-acc"></a>{{(user.childUsers != null) ? user.childUsers.length : 0}}</p>
                </div>
                <button class="circle__button" @click="goToSettings"><a class="icon-settings"></a></button>
            </div>
        </div>
        <div class="wrapper">
            <div class="user-list--container">
                <div class="search--container">
                    <div class="input--container">
                        <a class="icon-search"></a>
                        <input placeholder="Поиск" v-model="searchText">
                    </div>
                </div>
                <div class="header--container">
                    <p>Добавленные</p>
                </div>
                <div class="user--list">
                    <div class="user--list-item"  @click="selectDeleteUser($event,user1)" v-for="user1 of myUsers" v-bind:key="user1.id">
                        <div class="account-info--container">
                            <div class="avatar--container">
                                <img :src="user1.avatar">
                            </div>
                            <div class="name--container">
                                <p class="name__text">{{user1.name}}</p>
                                <p class="category__text">{{user1.typeName}}</p>
                            </div>
                        </div>
                        <div class="indicator--container">
                            <p><a class="icon-camera"></a>{{user1.camerasCount}}</p>
                        </div>
                    </div>
                </div>
                <div class="header--container">
                    <p>Список</p>
                </div>
                <div class="user--list">
                    <div class="user--list-item" @click="selectAddUser($event,user1)" v-for="user1 of availableUsers" v-bind:key="user1.id">
                        <div class="account-info--container">
                            <div class="avatar--container">
                                <img :src="user1.avatar">
                            </div>
                            <div class="name--container">
                                <p class="name__text">{{user1.name}}</p>
                                <p class="category__text">{{user1.typeName}}</p>
                            </div>
                        </div>
                        <div class="indicator--container">
                            <p><a class="icon-camera"></a>{{user1.camerasCount}}</p>
                        </div>
                    </div>


                </div>
            </div>
            <div class="pay--container">
                <payments></payments>
            </div>
        </div>
        <div class="selected-user--card" v-show="isShowDelete" ref="userCard">
            <div class="account-info--container">
                <div class="avatar--container">
                    <img :src="selectedUser.avatar">
                </div>
                <div class="name--container">
                    <p class="name__text">{{selectedUser.name}}</p>
                    <p class="category__text">{{selectedUser.typeName}}</p>
                </div>
            </div>
            <div class="indicator--container">
                <p><a class="icon-camera"></a>{{selectedUser.camerasCount}}</p>
            </div>
            <div class="button--container">
                <button class="btn btn__action" @click="goToUser">ПЕРЕЙТИ В ПРОФИЛЬ</button>
            </div>
            <div class="buttons--container">
                <button class="btn btn__create left" @click="removeUser"><img class="icon__exit" src="../../assets/icons/remove.svg">УДАЛИТЬ</button>
                <button class="btn btn__delete right"><img class="icon__exit" src="../../assets/icons/cart.svg">ДОБАВИТЬ</button>

            </div>
        </div>
        <div class="selected-user--card" v-show="isShowAdd" ref="userCard1">
            <div class="account-info--container">
                <div class="avatar--container">
                    <img :src="selectedUser.avatar">
                </div>
                <div class="name--container">
                    <p class="name__text">{{selectedUser.name}}</p>
                    <p class="category__text">{{selectedUser.typeName}}</p>
                </div>
            </div>
            <div class="indicator--container">
                <p><a class="icon-camera"></a>{{selectedUser.camerasCount}}</p>
            </div>
            <div class="button--container">
                <button class="btn btn__action" @click="goToUser">ПЕРЕЙТИ В ПРОФИЛЬ</button>
            </div>
            <div class="buttons--container">
                <button class="btn btn__delete left"><img class="icon__exit" src="../../assets/icons/remove.svg">УДАЛИТЬ</button>
                <button class="btn btn__create right" @click="addUser"><img class="icon__exit" src="../../assets/icons/cart.svg">ДОБАВИТЬ</button>

            </div>
        </div>
    </div>
</template>

<script>
    import Payments from "../../components/Payments";
    export default {
        name: "MultiAccountView",
        components: {Payments},
        data(){
            return {
                isShowDelete: false,
                isShowAdd: false,
                selectedUser: {},
              searchText: '',
            }
        },
        computed:{
            user(){
                return this.$store.getters.SELECTED_USER
            },
          myUsers(){
            const user = this.user;
            if (this.searchText.length === 0 ) return user.childUsers;


            return user.childUsers.filter(user => user.name.toLowerCase().includes(this.searchText.toLowerCase()) || this.getUsersCategory(user).toLowerCase().includes(this.searchText.toLowerCase()))

          },
            availableUsers(){
              const users = this.$store.getters.AVAILABLE_USERS
              if (this.searchText.length === 0 ) return users;

              return users.filter(user => user.name.toLowerCase().includes(this.searchText.toLowerCase()) || this.getUsersCategory(user).toLowerCase().includes(this.searchText.toLowerCase()))
            }
        },
        methods:{
          getSumCameras(user){
            let camsCount = 0
            for (user of user.childUsers){
              camsCount+= user.camerasCount
            }


            return camsCount;
          },
            goToMainList(){
                this.$router.push({name : "MainView"})

            },
            goToUser(){
                this.$router.push({name : "UserView",params : {id : this.selectedUser.id}})
            },
            getUsersCategory(user){
                if (user.type === 2){
                    let types = []
                    for (let us of user.childUsers){
                        types.push(us.typeName)
                    }
                    return Array.from(new Set([...types])).join(",")
                } else {
                    return user.typeName
                }
            },
            selectDeleteUser(event,user){
                if (this.selectedUser.id === user.id){
                    this.selectedUser = {}
                    this.isShowDelete = false
                } else {
                    this.isShowAdd = false
                    this.isShowDelete = true
                    this.selectedUser = {...user}

                    this.$refs.userCard.style.top = event.y+'px'
                    this.$refs.userCard.style.left = event.x+100+'px'
                }

            },
            selectAddUser(event,user){
                if (this.selectedUser.id === user.id){
                    this.selectedUser = {}
                    this.isShowAdd = false
                } else {
                    this.isShowDelete = false
                    this.isShowAdd = true
                    this.selectedUser = {...user}

                    this.$refs.userCard1.style.top = event.y+'px'
                    this.$refs.userCard1.style.left = event.x+100+'px'
                }
            },


            async addUser(){
              const user = await this.$store.dispatch("ADD_USER_TO_ACCOUNT",{masterUserId: this.user.id,slaveUserId: this.selectedUser.id})
              this.selectAddUser(null,this.selectedUser)
              this.$store.commit("SET_SELECTED_USER",user)
              await this.$store.dispatch("GET_AVAILABLE_USERS",{userId : this.user.id});

            },

            async removeUser(){
              const user = await this.$store.dispatch("REMOVE_USER_FROM_ACCOUNT",{masterUserId: this.user.id,slaveUserId: this.selectedUser.id})
              this.selectDeleteUser(null,this.selectedUser)
              this.$store.commit("SET_SELECTED_USER",user)
              await this.$store.dispatch("GET_AVAILABLE_USERS",{userId : this.user.id});
            },

          goToSettings(){
            this.$router.push({name: 'ChangeMultiDataView',params: {id: this.user.id}})
          }
        },
        async mounted(){
            const userId = this.$route.params.id;
            await this.$store.dispatch("GET_SELECTED_USER",{userId: userId})
            await this.$store.dispatch("GET_AVAILABLE_USERS",{userId : userId});
        }
    }
</script>

<style scoped lang="scss">
    .pay--container{
        width: calc(371px * 0.66);
        //min-height: calc(410px * 0.75);
        background: #FFFFFF;
        min-width: 320px;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.14);
        border-radius: 8px;
        margin-left: 40px;
        margin-top: calc(88px * 0.75);



        @include for-desktop-up{
            //width: 186px;
            //height: 205px;
            min-width: auto;
            border-radius: 4px;
            margin-left: 20px;
            box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
            margin-top: 44px;
            transform: translate(-10%, -20%) scale(.6);
        }
    }
    .user--view {
        width: 100%;
        height: 100%;

        .navbar {
            display: flex;

            .back__button {
                border: 0;
                outline: 0;
                background-image: url("../../assets/icons/gradient.svg");
                /* Sketch doesnt export gradient borders at this point */
                box-shadow: 0 2px 2px 0 rgba(20, 134, 255, 0.20);
              width: calc(72px * 0.66);
              height: calc(100px * 0.75);
              cursor: pointer;
              background-repeat: no-repeat;
              background-size: cover;
              @include for-desktop-up{
                width: 36px;
                height: 50px;
                box-shadow: 0 1px 1px 0 rgba(20, 134, 255, 0.20);


              }

                &:hover {
                    filter: brightness(87.5%);
                }
            }

            .user-info--container {
              width: calc(610px * 0.66);
                background: #ffffff;
                border: 1px solid #EBEBEB;
              height: calc(100px * 0.75);
                position: relative;

              @include for-desktop-up{
                width: 305px;
                height: 50px;
              }


                .circle__button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  top: calc(32px * 0.75);
                  right: calc(41px * 0.66);
                    position: absolute;
                    background: #7E72F2;
                    border-radius: 50%;
                  width: calc(36px * 0.75);
                  height: calc(36px * 0.75);
                    border: 0;
                    outline: 0;
                    cursor: pointer;

                  @include for-desktop-up{
                    width: 22px;
                    height: 22px;
                    top: 14px;
                    right: 16px;
                  }

                    &:hover {
                        filter: brightness(87.5%);
                    }

                    .icon-settings {
                        font-size: calc(18px - 4px);

                      @include for-desktop-up{
                        font-size: 11px;
                      }
                    }
                }


                .account-info--container {
                  margin-top: calc(18px * 0.75);
                  margin-left: calc(18px * 0.75);
                    display: flex;

                  @include for-desktop-up{
                    margin-top: 9px;
                    margin-left: 9px;
                  }

                    .avatar--container {
                      width: calc(37px * 0.75);
                      height: calc(37px * 0.75);
                        object-fit: cover;
                        border-radius: 50%;

                      @include for-desktop-up{
                        width: 19px;
                        height: 19px;
                      }

                        img{
                          width: calc(37px * 0.75);
                          height: calc(37px * 0.75);
                            object-fit: cover;
                            border-radius: 50%;

                          @include for-desktop-up{
                            width: 19px;
                            height: 19px;
                          }
                        }
                    }

                    .name--container {
                      margin-left: calc(26px * 0.75);


                      @include for-desktop-up{
                        margin-left: 12px;

                      }

                        .name__text {
                            font-family: Roboto, "sans-serif";
                            font-size: calc(14px - 2px);
                            color: #2C2C2C;
                            letter-spacing: 0;
                            margin-left: 0px;

                          @include for-desktop-up{
                            font-size: 7px;
                          }
                        }

                        .category__text {
                            font-family: Roboto, "sans-serif";
                            font-size: calc(12px - 2px);
                            color: #2C2C2C;
                            letter-spacing: 0;
                            margin-left: 0px;
                            margin-top: 7px;

                          @include for-desktop-up{
                            margin-top: 4px;
                            font-size: 6px;

                          }
                        }
                    }

                }

                .indicator--container {
                  margin-left: calc(18px * 0.75);
                  margin-top: calc(16px * 0.75);
                    display: flex;
                    align-items: center;

                  @include for-desktop-up{
                    margin-left: 9px;
                    margin-top: 7px;

                  }


                    .icon-finance {
                        font-size: 10px;

                      @include for-desktop-up{
                        font-size: 6px;
                      }
                    }

                    .icon-acc {
                        font-size: 10px;

                      @include for-desktop-up{
                        font-size: 8px;
                      }
                    }

                    .icon-camera {
                        font-size: 10px;

                      @include for-desktop-up{
                        font-size: 6px;
                      }
                    }

                    a {
                        margin-right: 10px;

                      @include for-desktop-up{
                        margin-right: 5px;
                      }
                    }

                    p {
                        opacity: 0.9;
                        font-family: Roboto, "sans-serif";
                        font-size: 12px;
                        color: #525252;
                        letter-spacing: 0.54px;
                        margin-left: 20px;


                      @include for-desktop-up{
                        margin-left: 10px;
                        font-size: 6px;

                      }
                    }

                    p:first-child {
                        margin-left: 0;
                    }
                }

            }

            .btn {
                width: 100px;
                height: 21px;
                background: #6F64F8;
                border-radius: 4px;
                font-family: Roboto, "sans-serif";
                font-size: 8px;
                letter-spacing: 0;
                border: 0;
                outline: 0;
                cursor: pointer;

                @include for-big-desktop-up {
                  width: calc(278px * 0.66);
                  height: calc(56px * 0.75);
                    border-radius: 6px;
                  font-size: calc(16px - 4px);
                  letter-spacing: 0;
                  margin-left: calc(49px * 0.75);
                  margin-top: calc(23px * 0.75);
                }

                &:hover {
                    filter: brightness(87.5%);
                }

                &.not_available {
                    pointer-events: none;
                    opacity: .5;
                }
            }

            .btn__action {
                background: #7E72F2;
                color: #FFFFFF;
            }

            .btn__img {
                background: #7E72F2;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;

                @include for-big-desktop-up {
                    width: 200px;
                    height: 48px;
                }

                img {
                    margin-right: 23px;

                }

            }

            .btn__close {
                background: #EDEDED;
                color: #2C2C2C;
                margin-right: 15px;

                @include for-big-desktop-up {
                    margin-right: 30px;
                }
            }
        }


        .user-list--container{
            margin-top: 37px;
            height: calc(100vh - 200px);
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            border-radius: 8px 0 0 0;
            width: calc(370px * 0.75);
            margin-left: 72px;
            overflow-y: scroll;

          @include for-desktop-up{
            margin-top: 18px;
            width: 175px;
            margin-left: 36px;
          }

            .header--container{
                height: calc(calc(17px + 16px + 26px) * 0.75);
                display: flex;
                align-items: center;
                border-bottom: 1px solid #EBEBEB;

              @include for-desktop-up{
                height: calc(9px + 8px + 13px);
              }

                p{
                    font-family: Roboto, "sans-serif";
                    font-size: calc(22px - 4px);
                    color: #7E72F2;
                    text-align: left;
                    margin-left: 18px;

                  @include for-desktop-up{
                    margin-left: 9px;
                    font-size: 11px;
                  }
                }
            }

            .user--list{
                height: auto;
                width: calc(370px * 0.75);

              @include for-desktop-up{
                width: 175px;
              }

                .user--list-item{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    background: #FFFFFF;
                    border-bottom: 1px solid #EBEBEB;
                    width: calc(370px * 0.75);
                    height: calc(100px * 0.75);
                    cursor: pointer;

                  @include for-desktop-up{
                    width: 175px;
                    height: 50px;
                  }

                  &:hover{
                    filter: brightness(95%);
                  }

                    .account-info--container {
                      margin-top: calc(18px * 0.75);
                      margin-left: calc(18px * 0.75);
                        display: flex;

                      @include for-desktop-up{
                        margin-top: 9px;
                        margin-left: 9px;
                      }

                        .avatar--container {
                          width: calc(37px * 0.75);
                          height: calc(37px * 0.75);
                            object-fit: cover;
                            border-radius: 50%;

                          @include for-desktop-up{
                            width: 19px;
                            height: 19px;
                          }

                            img{
                              width: calc(37px * 0.75);
                              height: calc(37px * 0.75);
                                object-fit: cover;
                                border-radius: 50%;

                              @include for-desktop-up{
                                width: 19px;
                                height: 19px;
                              }
                            }
                        }

                        .name--container {
                          margin-left: calc(26px * 0.75);

                          @include for-desktop-up{
                            margin-left:13px
                          }

                            .name__text {
                                font-family: Roboto, "sans-serif";
                                font-size: 12px;
                                color: #2C2C2C;
                                letter-spacing: 0;
                                margin-left: 0px;
                              text-overflow: ellipsis;
                              overflow: hidden;
                              width: 200px;
                              white-space: nowrap;

                              @include for-desktop-up{
                                font-size: 7px;

                                width: 120px;

                              }
                            }

                            .category__text {
                                font-family: Roboto, "sans-serif";
                                font-size: 10px;
                                color: #2C2C2C;
                                letter-spacing: 0;
                                margin-left: 0px;
                                margin-top: 7px;


                              @include for-desktop-up{
                                font-size: 6px;
                                margin-top: 3px;
                              }
                            }
                        }

                    }

                    .indicator--container {
                      margin-left: calc(18px * 0.75);
                      margin-top: calc(16px * 0.75);
                        display: flex;
                        align-items: center;

                      @include for-desktop-up{
                        margin-left: 9px;
                        margin-top: 8px;
                      }


                        .icon-finance {
                            font-size: 10px;


                          @include for-desktop-up{
                            font-size: 6px;

                          }
                        }

                        .icon-acc {
                            font-size: 10px;

                          @include for-desktop-up{
                            font-size: 8px;

                          }
                        }

                        .icon-camera {
                            font-size: 10px;

                          @include for-desktop-up{
                            font-size: 6px;

                          }
                        }

                        a {
                            margin-right: 10px;

                          @include for-desktop-up{
                            margin-right: 5px;

                          }
                        }

                        p {
                            opacity: 0.9;
                            font-family: Roboto, "sans-serif";
                            font-size: 12px;
                            color: #525252;
                            letter-spacing: 0.54px;
                            margin-left: 20px;

                          @include for-desktop-up{
                            margin-left: 10px;
                            font-size: 6px;

                          }

                        }

                        p:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            .search--container{
                margin-top:  calc(17px * 0.75);
                margin-left: calc(23px * 0.75);
                margin-bottom: calc(15px * 0.75);

              @include for-desktop-up{
                margin-top: 8px;
                margin-left:12px;
                margin-bottom: 15px;

              }

                .input--container{
                    border: 1px solid #DEDEDE;
                    border-radius: 17px;
                    height: calc(34px * 0.75);
                    width: calc(275px * 0.75);
                    display: flex;
                    align-items: center;

                  @include for-desktop-up{
                    border-radius: 8px;
                    height: 17px;
                    width: 138px;
                  }

                    a{
                        font-size: 10px;
                        margin-left: 14px;

                      @include for-desktop-up{
                        font-size: 5px;
                        margin-left: 7px;
                      }
                    }

                    input{
                        margin-left:9px;
                        width: calc(231px * 0.66);
                        font-family: Roboto, "sans-serif";
                        font-size: 10px;
                        color: black;
                        letter-spacing: 0;
                        border: 0;
                        outline: 0;

                        &::placeholder{
                            color: #999999;
                        }


                      @include for-desktop-up{
                        font-size: 5px;
                        margin-left:5px;
                        width: 115px;
                      }
                    }


                }
            }
        }


        .selected-user--card{
            position: absolute;
            width: calc(370px * 0.66);
            height: calc(190px * 0.75);
            background: #FFFFFF;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.14);
            border-radius: 8px;

          @include for-desktop-up{
            width: 175px;
            height: 95px;
            background: #FFFFFF;
            box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
            border-radius: 4px;
          }

            .account-info--container {
              margin-top: calc(18px * 0.75);
              margin-left: calc(18px * 0.75);
                display: flex;


              @include for-desktop-up{
                margin-top: 9px;
                margin-left: 9px;
              }
                .avatar--container {
                  width: calc(37px * 0.75);
                  height: calc(37px * 0.75);
                    object-fit: cover;
                    border-radius: 50%;

                  @include for-desktop-up{
                    width: 19px;
                    height: 19px;
                  }

                    img{
                      width: calc(37px * 0.75);
                      height: calc(37px * 0.75);
                        object-fit: cover;
                        border-radius: 50%;

                      @include for-desktop-up{
                        width: 19px;
                        height: 19px;
                      }
                    }
                }

                .name--container {
                  margin-left: calc(26px * 0.75);

                  @include for-desktop-up{
                    margin-left: 13px;

                  }

                    .name__text {
                        font-family: Roboto, "sans-serif";
                        font-size: 12px;
                        color: #2C2C2C;
                        letter-spacing: 0;
                        margin-left: 0px;

                      @include for-desktop-up{
                        font-size: 7px;

                      }
                    }

                    .category__text {
                        font-family: Roboto, "sans-serif";
                        font-size: 10px;
                        color: #2C2C2C;
                        letter-spacing: 0;
                        margin-left: 0px;
                        margin-top: 7px;

                      @include for-desktop-up{
                        font-size: 6px;
                        margin-top: 3px;

                      }
                    }
                }

            }

            .indicator--container {
              margin-left: calc(18px * 0.75);
              margin-top: calc(16px * 0.75);
                display: flex;
                align-items: center;

              @include for-desktop-up{
                margin-left: 9px;
                margin-top: 8px;
              }


                .icon-finance {
                    font-size: 10px;

                  @include for-desktop-up{
                    font-size: 6px;

                  }
                }

                .icon-acc {
                    font-size: 14px;

                  @include for-desktop-up{
                    font-size: 8px;

                  }
                }

                .icon-camera {
                    font-size: 10px;

                  @include for-desktop-up{
                    font-size: 6px;

                  }
                }

                a {
                    margin-right: 10px;

                  @include for-desktop-up{
                    margin-right: 5px;

                  }
                }

                p {
                    opacity: 0.9;
                    font-family: Roboto, "sans-serif";
                    font-size: 12px;
                    color: #525252;
                    letter-spacing: 0.54px;
                    margin-left: 20px;



                  @include for-desktop-up{
                    margin-left: 10px;
                    font-size: 6px;

                  }
                }

                p:first-child {
                    margin-left: 0;
                }
            }

            .button--container{
                display: flex;
                justify-content: flex-end;
                margin-right: 15px;
                margin-top: -1px;


              @include for-desktop-up{
                margin-right: 7px;


              }
            }

            .buttons--container{
                display: flex;
                margin-top: 17px;

              @include for-desktop-up{
                margin-top: 9px;


              }
            }
        }




        .btn{
            width: 100px;
            height: 21px;
            background: #6F64F8;
            border-radius: 4px;
            font-family: Roboto, "sans-serif";
            font-size: 8px;
            letter-spacing: 0;
            border: 0;
            outline: 0;
            cursor: pointer;

            @include for-big-desktop-up{
                width: calc(366px * 0.66);
                height: calc(48px * 0.75);
                border-radius: 6px;
                font-size: 16px;
                letter-spacing: 0;
            }

            &:hover{
                filter: brightness(87.5%);
            }

            &.not_available{
                pointer-events: none;
                opacity: .5;
            }
        }

        .btn__action{
            background: #6F64F8;
            border-radius: 6px;
            font-family: Roboto, "sans-serif";
            font-size: 10px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            width: calc(185px * 0.66);
            height: calc( 45px * 0.75);

          @include for-desktop-up{
            width: 95px;
            height: 22px;
            font-size: 7px;
            border-radius: 4px;
          }
        }

        .btn__create{
            background: #7E72F2;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 0 0 8px 0;
            font-family: Roboto, "sans-serif";
            font-size: 10px;
            letter-spacing: 0;

            @include for-big-desktop-up{
              width: calc(185px * 0.66);
              height: calc( 45px * 0.75);
            }

          @include for-desktop-up{
            width: 95px;
            height: 22px;
            font-size: 7px;
            border-radius: 0 0 4px 0;
          }

            img{
                margin-right: 10px;


              @include for-desktop-up{
                margin-right: 5px;
                width: 6px;

              }
            }

        }

        .btn__delete{
            color: #2C2C2C;
            display: flex;
            align-items: center;
            justify-content: center;


            background: #EDEDED;
            border-radius: 0 0 8px 0;
            font-family: Roboto, "sans-serif";
            font-size: 10px;
            letter-spacing: 0;
          pointer-events: none;



            @include for-big-desktop-up{
              width: calc(185px * 0.66);
              height: calc( 45px * 0.75);
            }

          @include for-desktop-up{
            width: 95px;
            height: 22px;
            font-size: 7px;
            border-radius: 0 0 4px 0;
          }

            img{
                margin-right: 10px;
                color: #2C2C2C;
                height: 12px;

              @include for-desktop-up{
                margin-right: 5px;
                width: 6px;

              }
            }

        }



    }


    .btn.left{
      border-radius: 0 0 0 8px;

      @include for-desktop-up{
        border-radius: 0 0 0 4px;
      }
    }

    .btn.right{
      border-radius: 0 0 8px 0;

      @include for-desktop-up{
        border-radius: 0 0 4px 0;
      }
    }
    .wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }
</style>
